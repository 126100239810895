/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import styles from "./blessing.module.css";
import { Grid } from "@mui/material";
import API from "../../services/apiUrl";
import swal from "sweetalert";

//images
import logo from "../../assets/images/Blessings/logo.png";
import one from "../../assets/images/Blessings/1.png";
import two from "../../assets/images/Blessings/2.png";
import three from "../../assets/images/Blessings/3.png";
import four from "../../assets/images/Blessings/4.png";
import five from "../../assets/images/Blessings/5.png";
import six from "../../assets/images/Blessings/6.png";
import seven from "../../assets/images/Blessings/7.png";
import eight from "../../assets/images/Blessings/8.png";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Blessing = ({ setTitle }) => {
  let query = useQuery();

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    amount: "",
    message: "",
    relation: "parent",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [kidName, setKidName] = useState("");
  const [formView, setFormView] = useState(false);
  const [htmlPage, setHtmlPage] = useState("");
  const [token] = useState(query.get("token"));

  console.log(token, "token");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const getData = async (token) => {
    try {
      const res = await axios.get(
        `${API.BASE_LINK}/userWallet/getBlessingAmount?token=${token}`
      );
      if (res.status === 200) {
        if (res?.data?.data?.amount < 100) {
          setFormValues({ ...formValues, amount: 100 });
        } else {
          setFormValues({ ...formValues, amount: res?.data?.data?.amount });
        }
        if (res?.data?.data?.kidName.length > 2) {
          setKidName(res?.data?.data?.kidName);
        } else {
          console.log("Can't find Kid.");
          swal(
            "Oops!",
            "the blessing link is expired. please ask kid to send a new one.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      swal(
        "Oops!",
        error?.response?.data?.message === "jwt malformed"
          ? "Invalid Blessing Request"
          : error?.response?.data?.message,
        "error"
      );
    }
  };

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const validateEmail = (email) => {
    try {
      const atSign = formValues.email.indexOf("@");
      const dotSign = formValues.email.indexOf(".");
      const atsignCount = formValues.email.match(/[@]/g).length;
      const dotCount = formValues.email.match(/[.]/g).length;
      if (
        atSign < 1 ||
        dotSign + 2 >= email.length ||
        atsignCount !== 1 ||
        dotCount < 1
      ) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error, "error");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      // console.log(
      //   !/^[a-zA-Z\s]+$/.test(formValues?.name) &&
      //     !/^[\s]+$/.test(formValues?.name),
      //   formValues?.name,
      //   "Name Validation"
      // );
      if (!formValues.name && !formValues.phone && !formValues.email) {
        swal("Oops!", "Please enter all details.", "error");
        return;
      } else if (
        formValues?.name.length <= 1 ||
        (!/^[a-zA-Z\s]+$/.test(formValues?.name) &&
          !/^[\s]+$/.test(formValues?.name))
      ) {
        swal("Oops!", "Please enter correct name.", "error");
        return;
      } else if (formValues.phone.length < 10) {
        swal("Oops!", "Please enter correct phone number.", "error");
        return;
      } else if (
        formValues.email == "" ||
        !formValues.email?.match(validRegex) ||
        !validateEmail(formValues.email) ||
        !formValues.email
      ) {
        swal("Oops!", "Please enter correct email address.", "error");
        return;
      } else if (formValues.amount < 100) {
        swal("Oops!", "Amount can not be less then 100.", "error");
        return;
      } else {
        const res = await axios.post(
          `${API.BASE_LINK}/userWallet/web/CreateBlessingTransaction?token=${token}`,
          formValues
        );
        if (res.status === 201) {
          setFormView(true);
          setHtmlPage(res.data.data);
        }
      }
    } catch (error) {
      // console.log(error?.response?.data?.message, "Error Message");
      swal("Oops!", error?.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    setTitle("Blessings | Good Good Piggy");
    if (token !== null && token !== undefined) {
      console.log("ha m chl rha hu kya kr lega c");
      getData(query.get("token"));
    }
  }, [query]);

  useEffect(() => {
    if (formView && htmlPage.length > 0) {
      let form = document.getElementById("form");
      form.submit();
    }
  }, [formView, htmlPage.length]);

  return (
    <>
      {!formView ? (
        <>
          <div style={{ backgroundColor: "#caaacd" }}>
            <div className={styles.header}>
              <img className={styles.logo} src={logo} alt="" />
              <div className={styles.text}>
                <h1 className={styles.texts}>Give a blessing to {kidName}</h1>
                <div className={styles.ptext}>
                  <img className={styles.logo1} src={logo} alt="" />
                  <p style={{ fontFamily: "cookies", color: "#fcf3dd" }}>
                    A love filled gesture straight into their piggy bank
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#fcf3dd", padding: "11% 4%" }}>
            {/* <textarea
              onChange={handleChange}
              name="letter"
              value={formValues.message}
              className={styles.letter}
            ></textarea> */}

            <img className={styles.img} src={one} alt="" />

            <div className={styles.inputs}>
              <div style={{ marginTop: "6%" }} className={styles.input}>
                <p style={{ margin: "auto 0px", fontWeight: "600" }}>
                  WHATS YOUR NAME*{" "}
                </p>
                <input
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  placeholder="YOUR NAME"
                  className={styles.ifield}
                  type="text"
                ></input>
              </div>

              <div className={styles.input}>
                <p style={{ margin: "auto 0px", fontWeight: "600" }}>
                  {" "}
                  SELECT RELATIONSHIP*{" "}
                </p>
                <select
                  className={styles.ifield}
                  name="relation"
                  onChange={handleChange}
                  value={formValues.relation}
                >
                  <option disabled value="not-Selected">
                    DROPDOWN TO SELECT RELATIONSHIP
                  </option>
                  <option value="parent">Parents</option>
                  <option value="guardian">Guardian</option>
                  <option value="relative">Relative</option>
                </select>
              </div>

              <div className={styles.input}>
                <p style={{ margin: "auto 0px", fontWeight: "600" }}>
                  YOUR CONTACT NUMBER*{" "}
                </p>
                <input
                  name="phone"
                  value={formValues.phone}
                  onChange={handleChange}
                  placeholder="CONTACT NUMBER"
                  className={styles.ifield}
                  type="number"
                ></input>
              </div>

              <div className={styles.input}>
                <p style={{ margin: "auto 0px", fontWeight: "600" }}>
                  YOUR EMAIL*{" "}
                </p>
                <input
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  placeholder="EMAIL"
                  className={styles.ifield}
                  type="email"
                ></input>
              </div>

              <div className={styles.input}>
                <p style={{ margin: "auto 0px", fontWeight: "600" }}>
                  BLESSINGS AMOUNT*{" "}
                </p>
                <input
                  name="amount"
                  value={formValues.amount}
                  onChange={handleChange}
                  placeholder="BLESSINGS AMOUNT"
                  className={styles.ifield}
                  type="number"
                ></input>
              </div>

              <div className={styles.input}>
                <p style={{ margin: "auto 0px", fontWeight: "600" }}>
                  TYPE A MESSAGE*{" "}
                </p>
                <input
                  name="message"
                  value={formValues.message}
                  onChange={handleChange}
                  placeholder="ENTER MESSAGE"
                  className={styles.ifield}
                  type="text"
                ></input>
              </div>

              <div className={styles.submit} onClick={handleSubmit}>
                <img className={styles.submitbutton} src={two}></img>
                <button
                  // ref={inputRef}
                  // onClick={handleSubmit}
                  style={{ display: "none" }}
                >
                  submit
                </button>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#BF9FCC", paddingBottom: "20px" }}>
            <div
              className={styles.icon}
              align="center"
              style={{ width: "80%", margin: " auto 10%" }}
            >
              <h1 style={{ color: "#fcf3dd", textAlign: "center" }}>
                Feel happy as you are encouraging
              </h1>

              <Grid container spacing={2} columns={12}>
                <Grid item xs={4}>
                  <img style={{ width: "43%" }} src={three}></img>
                  <p className={styles.icontext}>FINANCIAL LITERACY</p>
                  <p className={styles.idesc}>
                    TEACHING THEM ABOUT MONEY MANAGEMENT AND THE IMPORTANCE OF
                    SAVING FROM AN EARLY AGE CAN INSTILL GOOD FINANCIAL HABITS
                    THAT WILL BENEFIT THEM THROUGHOUT THEIR LIFE. BY GIVING THEM
                    MONEY AND ENCOURAGING THEM TO SAVE IT IN A PIGGY BANK, YOU
                    ARE INTRODUCING THEM TO THE CONCEPT OF SAVING AND TEACHING
                    THEM THE VALUE OF MONEY.
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <img style={{ width: "40%" }} src={four}></img>
                  <p className={styles.icontext}>GOAL SETTING</p>
                  <p className={styles.idesc}>
                    SAVING MONEY IN A PIGGY BANK ALLOWS YOUR THEM TO SET
                    FINANCIAL GOALS AND WORK TOWARDS ACHIEVING THEM. IT CAN BE A
                    TANGIBLE REPRESENTATION OF THEIR SAVINGS PROGRESS, AS THEY
                    CAN SEE THE MONEY ACCUMULATING IN THE PIGGY BANK. THIS CAN
                    HELP THEM DEVELOP PATIENCE, DISCIPLINE, AND A SENSE OF
                    ACCOMPLISHMENT WHEN THEY REACHES THEIR SAVINGS GOALS.
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <img style={{ width: "40%" }} src={five}></img>
                  <p className={styles.icontext}>FINANCIAL RESPONSIBILITY</p>
                  <p className={styles.idesc}>
                    BY giving them money and encouraging them to save, you are
                    giving them a sense of financial responsibility. they will
                    learn to make choices about how to allocate their money,
                    understand the difference between wants and needs, and gain
                    confidence in managing their finances responsibly.
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <img style={{ width: "43%" }} src={six}></img>
                  <p className={styles.icontext}>SENSE OF OWNERSHIP</p>
                  <p className={styles.idesc}>
                    WHEN YOU GIVE MONEY AND ENCOURAGE THEM TO SAVE IT IN A PIGGY
                    BANK, YOU ARE INSTILLING A SENSE OF OWNERSHIP AND
                    RESPONSIBILITY IN HIM. THE PIGGY BANK BECOMES HIS PERSONAL
                    SAVINGS TOOL, AND TAKES THEM ON THE RESPONSIBILITY OF TAKING
                    CARE OF IT AND MANAGING THEIR SAVINGS. THIS SENSE OF
                    OWNERSHIP CAN FOSTER A POSITIVE ATTITUDE TOWARDS MONEY AND
                    PERSONAL BELONGINGS, TEACHING THEM TO VALUE AND TAKE PRIDE
                    IN WHAT THEY HAVE.
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <img style={{ width: "45%" }} src={seven}></img>
                  <p className={styles.icontext}>EMERGENCY FUND</p>
                  <p className={styles.idesc}>
                    HAVING A SAVINGS HABIT FROM A YOUNG AGE CAN HELP THEM BUILD
                    AN EMERGENCY FUND. ENCOURAGING THEM TO SAVE A PORTION OF
                    THEIR MONEY IN THE PIGGY BANK CAN BE A VALUABLE LESSON IN
                    BEING PREPARED FOR UNEXPECTED EXPENSES OR FINANCIAL
                    CHALLENGES THAT MAY ARISE IN THE FUTURE.
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <img style={{ width: "40%" }} src={eight}></img>
                  <p className={styles.icontext}>DELAYED GRATIFICATION</p>
                  <p className={styles.idesc}>
                    {" "}
                    IN TODAY'S WORLD, INSTANT GRATIFICATION IS PREVALENT, AND
                    TEACHING CHILDREN THE VALUE OF DELAYED GRATIFICATION CAN BE
                    HIGHLY VALUABLE. SAVING MONEY IN A PIGGY BANK HELPS THEM
                    LEARN THAT BY POSTPONING SPENDING AND SAVING INSTEAD, THEY
                    CAN AFFORD MORE SIGNIFICANT PURCHASES OR EXPERIENCES IN THE
                    FUTURE.
                  </p>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      ) : (
        <>
          {htmlPage ? (
            <PaymentPage
              paymentData={htmlPage.paymentObj}
              paymentURL={htmlPage.paymentUrl}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

const PaymentPage = ({ paymentData, paymentURL }) => {
  const [axisPaymentObj] = useState(paymentData);
  useEffect(() => {
    let form = document.getElementById("form");
    form.submit();
  }, []);
  return (
    <>
      <div>
        {axisPaymentObj ? (
          <>
            <form
              id="form"
              style={{ display: "none" }}
              action={paymentURL}
              method="POST"
              encType="application/x-www-form-urlencoded"
            >
              <input
                style={{ display: "none" }}
                type="text"
                name="merchantId"
                value={axisPaymentObj.merchantId}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="callbackUrl"
                value={axisPaymentObj.callbackUrl}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="merchantTxnId"
                value={axisPaymentObj.merchantTxnId}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="merchantTxnAmount"
                value={axisPaymentObj.merchantTxnAmount}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="Currency"
                value={axisPaymentObj.Currency}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="timestamp"
                value={axisPaymentObj.timestamp}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="Signature"
                value={axisPaymentObj.Signature}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="customerId"
                value={axisPaymentObj.customerId}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="customerName"
                value={axisPaymentObj.customerName}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="customerEmailId"
                value={axisPaymentObj.customerEmailId}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="customerMobileNo"
                value={axisPaymentObj.customerMobileNo}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="customerPIN"
                value={axisPaymentObj.customerPIN}
              />
            </form>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Blessing;
