import "./App.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blessing from "./pages/Blessings/Blessings";
import EmailVerify from "./pages/EmailVerify/EmailVerify";
import Redirection from "./pages/Redirection";

import { RootProvider } from "./context/root-Context";
import { PageTitleContext } from "./context/PageTitle-Context";

function App() {
  // const [title, setTitle] = useContext(PageTitleContext);
  const [title, setTitle] = useState("");
  return (
    <RootProvider>
      <HelmetProvider>
        <Helmet>
          <title>{title.length > 2 ? title : "Good Good Piggy"}</title>
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route
              exact="true"
              path="/"
              element={<Home setTitle={(x) => setTitle(x)} />}
            />
            <Route
              exact="true"
              path="/blessings?"
              element={<Blessing setTitle={(x) => setTitle(x)} />}
            />
            <Route
              exact="true"
              path="/email/verify?"
              element={<EmailVerify setTitle={(x) => setTitle(x)} />}
            />
             <Route
              exact="true"
              path="/app/download"
              element={<Redirection setTitle={(x) => setTitle(x)} />}
            />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </RootProvider>
  );
}

export default App;
