import React, { createContext, useEffect, useState } from "react";
export const PageTitleContext = createContext();

export const PageTitleProvider = ({ children }) => {
  const [title, setTitle] = useState("");
  useEffect(() => {
    console.log(title, "AppLockModel");
  }, [title]);
  return (
    <>
      <PageTitleContext.Provider value={[title, setTitle]}>
        {children}
      </PageTitleContext.Provider>
    </>
  );
};
