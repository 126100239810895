import React from "react";
import { PageTitleProvider } from "./PageTitle-Context";

export const RootProvider = ({ children }) => {
  return (
    <>
      <PageTitleProvider>{children}</PageTitleProvider>
    </>
  );
};
