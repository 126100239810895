import { useState, CSSProperties, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import BounceLoader from "react-spinners/BounceLoader";
import MoonLoader from "react-spinners/MoonLoader";

// const override: CSSProperties = {
//   display: "block",
//   margin: "auto",
//   borderColor: "red",
// };

const Home = ({ setTitle }) => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#723789");

  useEffect(() => {
    setTitle("Good Good Piggy");
    setTimeout(() => {
      window.location.replace("https://goodgoodpiggy.com/");
    }, 500);
  }, []);

  return (
    // <div className="sweet-loading">

    //   {/* <ClipLoader
    //     color={color}
    //     loading={loading}
    //     cssOverride={override}
    //     size={150}
    //     aria-label="Loading Spinner"
    //     data-testid="loader"
    //   />
    //   <BounceLoader
    //     color={color}
    //     loading={loading}
    //     cssOverride={override}
    //     size={150}
    //     aria-label="Loading Spinner"
    //     data-testid="loader"
    //   /> */}
    //    <MoonLoader
    //     color={color}
    //     loading={loading}
    //     cssOverride={override}
    //     size={150}
    //     aria-label="Loading Spinner"
    //     data-testid="loader"
    //   />
    // </div>
    <div className="sweet-loading">
      <h1>Good Good Piggy...</h1>
    </div>
  );
};

export default Home;
